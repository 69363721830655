<template>
  <div
    v-if="isInit"
    class="okeyhotel-chatbot"
    :style="cssVars"
  >
    <div class="okeyhotel-start okeyhotel-start__container">
      <!-- chat button -->
      <chatbot-start-button
        :is-active="isShowChat"
        :is-show-notification="showNotification"
        @toggle="toggleChat(); hideStart = true"
      />
      <!-- welcome message -->
      <chatbot-start-message
        v-if="showNotification"
        :message="startMessage"
      />
      <!-- chat -->
      <chatbot-block
        :logo="chatbotSettings.logo"
        :title="chatbotSettings.name"
        :is-show-chat="isShowChat"
        :is-show-menu="isShowMenu"
        :messages="chatHistory"
        :commands-list="commands"
        :menu-items="chatbotSettings.commands"
        :powered-link="chatbotSettings.powered_link"
        :powered-text="chatbotSettings.powered_text"
        @toggle="toggleChat"
        @update="getMessageAnswerAPI"
      >
        <template slot='loader'>
          <chatbot-loader
            v-if="isBotThinking"
          />
        </template>
      </chatbot-block>
    </div>
  </div>
</template>

<script>
import { chatbotService } from '../services/services.js'
import { OKEYHOTEL_CHATBOT_ID } from '../services/events.js'

export default {
  name: "chatbot",
  components: {
    'chatbot-start-message': () => import('./ChatbotStartMessage.vue'),
    'chatbot-start-button': () => import('./ChatbotStartButton.vue'),
    'chatbot-block': () => import('./ChatbotBlock.vue'),
    'chatbot-loader': () => import('./ChatbotLoader.vue'),
  },
  data() {
    return {
      isInit: false,
      isShowChat: false,
      isShowStartMessage: false,
      isShowMenu: false,
      hideStart: false,
      chatbotSettings: {},
      startMessage: '',
      themeColor: '',
      chatbotLogo: '',
      timer: null,
      chatbotTimer: null,
      data: {
        message: {
          payload: {uuid: ''},
          data: {text: ''}
        },
      },
      chatHistory: [],
      requestProcessing: false,
      isBotThinking: false,
    }
  },
  computed: {
    cssVars() {
      return {
        '--okeyhotel-main-color': this.chatbotSettings.main_color,
        '--okeyhotel-message-bg-color': this.chatbotSettings.messages_bg_color,
        '--okeyhotel-shadow-color': this.chatbotSettings.shadow_color,
      }
    },
    showNotification() {
      return this.isShowStartMessage && !this.isShowChat && !this.hideStart && Boolean(this.startMessage)
    },
    /* returns list of commands from menu
    and messages without duplicates*/
    commands() {
      const messageCommands = []
      this.chatHistory.forEach(item => {
        if (item.buttons !== null && item.buttons.length > 0) {
          const arr = item.buttons.map(elem => {
            const [description, command] = elem
            return { description, command }
          })
          messageCommands.push(...arr)
        }
      })
      return [...this.chatbotSettings.commands, ...messageCommands].filter((item, index, self) =>
        index === self.findIndex(element => (
          element.command === item.command && element.description === item.description
        ))
      )
    }
  },
  mounted() {
    this.getInitSettings()
  },
  methods: {
    toggleChat() {
      this.isShowChat = !this.isShowChat
      if (this.isShowChat) this.checkUUID()
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    checkUUID() {
      let uuid = this.getOrSetChatUUID()
      const openDialogMessage = this.chatbotSettings.message_open_dialog
      const hasOpenMessage = this.chatHistory.length === 1 && this.chatHistory[0].text[0][0] === openDialogMessage
      if (uuid) {
        this.getMessagesHistory(uuid)
      } else {
        if (!hasOpenMessage) {
          this.chatHistory.unshift(this.saveUserMessage(openDialogMessage, 2))
        }
      }
    },
    getOrSetChatUUID(uuid) {

      const storageUUID = localStorage.getItem(OKEYHOTEL_CHATBOT_ID) || null;

      if (!uuid) {
        return storageUUID
      }

      if (!storageUUID && uuid) {
        localStorage.setItem(OKEYHOTEL_CHATBOT_ID, uuid)
      }
  
      return uuid
    },
    prepareMessage(message) {
      let uuid = this.getOrSetChatUUID(message.uuid)
      if (!message.payload.uuid) {
        message.payload.uuid = uuid || null
      }
      return message
    },
    getInitSettings() {
      chatbotService.getInitSettings()
      .then(response => {
        this.chatbotSettings = response
        this.isInit = true
        this.startMessage = this.getOrSetChatUUID() ? this.chatbotSettings.client_return_message : this.chatbotSettings.hint_message
        this.showStartMessage()
      })
      .catch(error => {
        console.error(error)
      })
    },
    getMessagesHistory(uuid) {
      const parameters = { uuid }
      chatbotService.getMessagesHistory(parameters)
        .then(response => {
          this.chatHistory = response
        })
        .catch(error => {
          console.error(error)
        })
    },
    /**
     * Send API with question to ChatBot.
     */
    getMessageAnswerAPI(message) {
      this.startRequest()
      this.data.message.data.text = message
      this.data.message.payload.uuid = this.getOrSetChatUUID() || null
      chatbotService.getMessageAnswerRequest(this.data)
      .then(response => {
          this.stopRequest()
          this.chatHistory.unshift(this.saveUserMessage(message, 1))
          this.startBotThinking()
          const timeout = 2000
          this.chatbotTimer = setTimeout(() => {
            this.stopBotThinking()
            this.processMessage(response)
          }, timeout)
      })
    },
    processMessage(message) {
      this.getOrSetChatUUID(message.uuid)
      this.chatHistory.unshift(message)
      this.data.message.data.text = ''
    },
    /* set small delay after
    chat initialization */
    showStartMessage() {
      const SECONDS = 1000
      const timeout = this.chatbotSettings.show_hint_seconds * SECONDS
      if (this.chatbotSettings.hint_message) {
        this.timer = setTimeout(() => { this.isShowStartMessage = true }, timeout)
      }
    },
    startRequest() {
      this.requestProcessing = true
    },
    stopRequest() {
      this.requestProcessing = false
    },
    startBotThinking() {
      this.isBotThinking = true
    },
    stopBotThinking() {
      this.isBotThinking = false
    },
    saveUserMessage(message, role) {
      const rolePresenation = role === 1 ? 'to' : 'from'
      return {
        source: role,
        text: [[message]],
        source_text: rolePresenation,
        uuid: this.getOrSetChatUUID() || null,
        json_answer: null,
        buttons: null,
      }
    },
  },
}
</script>