import {
  CHATBOT_INIT_SETTINGS_URL,
  CHATBOT_MESSAGES_HISTORY_URL,
  CHATBOT_GET_MESSAGE_ANSWER_URL,
} from './events.js'
import { HEADERS } from './headers.js'
import { errors } from './errors.js'

class Service {
  getStaticRequest(url) {
    return fetch(url)
  }

  getRequest(url) {
    return fetch(url, {
      headers: HEADERS,
    })
  }

  postRequest(url, data) {
    return fetch(url, {
      method: 'POST',
      headers: HEADERS,
      body: data,
    })
  }

  getInitSettings() {
    return this.transformResponse(
      this.getStaticRequest(CHATBOT_INIT_SETTINGS_URL),
      errors.initSettings
    )
  }

  getMessagesHistory(parameters) {
    return this.transformResponse(
      this.getRequest(`${CHATBOT_MESSAGES_HISTORY_URL}${this.createParameteres(parameters)}`),
      errors.messagesHistory
    )
  }

  getMessageAnswerRequest(data) {
    return this.transformResponse(
      this.postRequest(CHATBOT_GET_MESSAGE_ANSWER_URL, this.transformData(data)),
      errors.messagesHistory
    )
  }

  transformResponse(promise, error) {
    return promise.then(response => {
      if (!response.ok) {
        throw new Error(error)
      }
      return response.json()
    })
  }

  transformData(data) {
    return JSON.stringify(data)
  }

  createParameteres(obj) {
    return `?${new URLSearchParams(obj).toString()}`
  }
}

const chatbotService = new Service()

export { chatbotService }