const okeyhotelChatbotId = window.OKEYHOTEL_CHATBOT_ID
const OKEYHOTEL_CHATBOT_ID = okeyhotelChatbotId
const generateStaticUrl = (path) => `https://okeyhotel.com/static/${path}`
const generateServerUrl = (path) => `https://okeyhotel.com/chatbot/chatbot/${okeyhotelChatbotId}/${path}`

const CHATBOT_INIT_SETTINGS_URL = generateStaticUrl(`bot.settings${okeyhotelChatbotId}.json`)
const CHATBOT_MESSAGES_HISTORY_URL = generateServerUrl('chat-messages/')
const CHATBOT_GET_MESSAGE_ANSWER_URL = generateServerUrl('receive-message/')

export {
  CHATBOT_INIT_SETTINGS_URL,
  CHATBOT_MESSAGES_HISTORY_URL,
  CHATBOT_GET_MESSAGE_ANSWER_URL,
  OKEYHOTEL_CHATBOT_ID
}