/**
 * Returns the value of a cookie using JavaScript. Inspired by
 * {@link https://docs.djangoproject.com/es/1.9/ref/csrf/#ajax}.
 *
 * @param {String} name - The cookie name.
 * @returns {String} The cookie value.
 */
 let getCookie = function (name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';'),
      i, cookie;
    for (i = 0; i < cookies.length; i++) {
      cookie = cookies[i].trim();

      /** Does this cookie string begin with the name we want? */
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }

  return cookieValue;
};

const HEADERS = {
  'X-CSRFToken': getCookie('csrftoken') || document.body.getAttribute('data-token'),
  'content-type': 'application/json; charset=utf-8',
}

export { HEADERS }